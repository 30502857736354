import type { ApiResponse } from '~/server/utils/errorHandler'

const ERR_MESSAGE = 'For security reasons, your session has expired. Please continue your claim using the reference code provided to you.'

const useApiFetch = async <T>(url: string, options?: any): Promise<ApiResponse<T>> => {
  // test for a session on the client - if it doesnt exist reset the session
  const session = useCookie('shackleAuth.session')
  const websiteStore = useWebsiteStore()

  if (session.value === null || session.value === undefined) {
    try {
      // Try to create a new session first
      await $fetch('/api/auth')
    } catch (error) {
      // If session creation fails, then reset and reload
      const beforeReset = () => {
        useUserStore().$reset()
      }
      
      await resetClientStorage(false, () => beforeReset())
      if (import.meta.client) {
        websiteStore.setClaimError(ERR_MESSAGE)
        window.location.reload() // 🙄
      }
    }
  }

  return await $fetch<ApiResponse<T>>(`/api${url}`, options)
}

export default useApiFetch;