import { defineStore } from "pinia";

interface AuthResponse {
  user: { id: string };
  accessToken: string;
  refreshToken: string;
}

export const useUserStore = defineStore("userStore", {
  persist: true,
  state: () => ({
    userId: null as string | null,
    accessToken: "",
    refreshToken: "",
  }),
  getters: {
    isAuthenticated: (state) => !!state.userId,
    hasValidSession: (state) => !!state.accessToken && !!state.refreshToken
  },
  actions: {
    async initializeUserSession() {
      const session = useCookie<AuthResponse>("shackleAuth.session");

      if (session.value === undefined) {
        const { data } = await useApiFetch<AuthResponse>('/auth');
        if (!data) {
          throw createError({
            statusCode: 401,
            message: 'Failed to create session'
          });
        }
        this.userId = data.user.id;
        this.accessToken = data.accessToken;
        this.refreshToken = data.refreshToken;
      } else {
        this.accessToken = session.value.accessToken;
        this.userId = session.value.user.id;
        this.refreshToken = session.value.refreshToken;
      }
    },
  },
});
