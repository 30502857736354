import { Date as ProtoDate } from "~/grpc/proto/google/type/date_pb"

const parseLocalizedDate = (dateStr: string): { year: number; month: number; day: number } => {
  // Try parsing with the browser's Date constructor
  const date = new Date(dateStr)
  
  // Check if the date is valid
  if (isNaN(date.getTime())) {
    throw new Error('Invalid date format. Please enter a valid date in your local format.')
  }

  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1, // getMonth() returns 0-11
    day: date.getDate()
  }
}

const protoDateToInputString = (protoDate: ProtoDate): string => {
  if (!protoDate) throw new Error('date should not be undefined')
  const { year, month, day } = protoDate
  const protoYearPadded = year.toString().padStart(4, '0')
  const protoMonthPadded = month.toString().padStart(2, '0')
  const protoDayPadded = day.toString().padStart(2, '0')
  return `${protoYearPadded}-${protoMonthPadded}-${protoDayPadded}`
}

const dateToProtoDate = (date: ProtoDate | string): ProtoDate => {
  if (!date) throw new Error('date should not be undefined')
  const protoDate = new ProtoDate();

  if (typeof date === 'string') {
    let parsedDate: { year: number; month: number; day: number }
    
    try {
      // Try parsing as localized date first
      parsedDate = parseLocalizedDate(date)
    } catch {
      // If localized parsing fails, try ISO format as fallback
      const [year, month, day] = date.split('-')
      parsedDate = {
        year: parseInt(year),
        month: parseInt(month),
        day: parseInt(day)
      }
    }

    protoDate.year = parsedDate.year
    protoDate.month = parsedDate.month
    protoDate.day = parsedDate.day
  } else {
    protoDate.day = date.day
    protoDate.month = date.month
    protoDate.year = date.year
  }

  return protoDate;
}

const formatDate = (date: Date): string => {
  return date.toISOString().split('T')[0];
};

const todayYMD = (dayOffset: number = 0): string => {
  const date = new Date();
  date.setDate(date.getDate() + dayOffset);
  return formatDate(date);
}

const yearsAgoYMD = (yearsAgo: number): string => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - yearsAgo);
  return formatDate(date);
}

const dateToMaturity = (birthDate: string) => {
  const parsedDate = dateToProtoDate(birthDate)
  const birthDateObj = new Date(parsedDate.year, parsedDate.month - 1, parsedDate.day)
  const today = new Date()
  
  let age = today.getFullYear() - birthDateObj.getFullYear()
  const monthDiff = today.getMonth() - birthDateObj.getMonth()
  const dayDiff = today.getDate() - birthDateObj.getDate()

  if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) age--
  return age < 18 ? 'child' : 'adult'
}

export {
  dateToProtoDate,
  protoDateToInputString,
  todayYMD,
  yearsAgoYMD,
  dateToMaturity
}
