<script setup lang="ts">
const { initializeUserSession } = useUserStore()
await callOnce(initializeUserSession)
</script>

<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<style>
@font-face {
    font-weight: 400;
    font-family: "Circular Std";
    src: url("~/assets/fonts/CircularStd-Book.ttf") format("truetype");
}

@font-face {
    font-weight: 600;
    font-family: "Circular Std";
    src: url("~/assets/fonts/CircularStd-Bold.ttf") format("truetype");
}

@font-face {
    font-weight: 300;
    font-family: "Circular Std";
    src: url("~/assets/fonts/CircularStd-Light.otf") format("opentype");
}

@font-face {
    font-weight: 500;
    font-family: "Circular Std";
    src: url("~/assets/fonts/CircularStd-Medium.ttf") format("truetype");
}

a[href]:not([data-btn]) {
  @apply text-teal-500 underline underline-offset-2;
}

html {
  font-family: "Circular Std";
}
</style>
